<template>
	<div class="news-list">
        <div class="news-item level0">
            <div class="row">
                <span class="create-time">2020-06-12</span>
                <span class="status">待審核</span>
            </div>
            <div class="title-wrap">
                <span class="type">#内科</span>
                <h2 class="title">肝酵素偏高，食中藥可幫助調低指數跟其他相關的指數吗？</h2>
            </div>
            <div class="tags">
                <span class="tag">養生</span>
                <span class="tag">夏日進補</span>
                <span class="tag">食譜</span>
            </div>
        </div>
        <div class="news-item level1">
            <div class="row">
                <span class="create-time">2020-06-12</span>
                <span class="status">正常</span>
            </div>
            <div class="title-wrap">
                <span class="type">#内科</span>
                <h2 class="title">肝酵素偏高，食中藥可幫助調低指數跟其他相關的指數吗？</h2>
            </div>
            <div class="tags">
                <span class="tag">養生</span>
                <span class="tag">夏日進補</span>
                <span class="tag">食譜</span>
            </div>
        </div>
        <div class="news-item level2">
            <div class="row">
                <span class="create-time">2020-06-12</span>
                <span class="status">推薦</span>
            </div>
            <div class="title-wrap">
                <span class="type">#内科</span>
                <h2 class="title">肝酵素偏高，食中藥可幫助調低指數跟其他相關的指數吗？</h2>
            </div>
            <div class="tags">
                <span class="tag">養生</span>
                <span class="tag">夏日進補</span>
                <span class="tag">食譜</span>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		created: function(){
			var userInfo = this.$store.getters.userInfo
			var isDoctorUser = !! (userInfo && userInfo.doctorglobaluserid)
			if(!isDoctorUser) {
				this.$router.push('/user/home')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.news-list {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		padding: 32px 24px;
		margin-top: 16px;
	}

	.news-list .news-item {
		display: flex;
		flex-direction: column;
		padding: 24px;
		background-color: #EFF4F9;
		border-radius: 16px;
		margin-bottom: 24px;
	}

	.news-list .news-item:active {
		opacity: 7;
	}

	.news-list .news-item .row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.news-list .news-item .create-time {
		color: #969696;
		font-size: 24px;
		line-height: 32px;
		font-weight: 400;
	}

	.news-list .news-item .status {
		border-radius: 16px;
		background-color: #FFE3D5;
		height: 32px;
		line-height: 32px;
		width: 96px;
		text-align: center;
		color: #FF8000;
		font-weight: bold;
		font-size: 24px;
	}

	.news-list .news-item.level1 .status {
		color: #2A8737;
		background-color: #C5E6C7;
	}

	.news-list .news-item.level2 .status {
		color: #EE1B23;
		background-color: #FFDCDD;
	}

	.news-list .news-item .tags {
		display: flex;
		flex-direction: row;
		margin-top: 19px;
	}

	.news-list .news-item .tags > .tag {
		height: 36px;
		line-height: 36px;
		padding: 0px 11px 0px 10px;
		margin-left: 20px;
		background-color: #fff;
		color: #36C4D0;
		font-size: 24px;
		font-weight: bold;
	}

	.news-list .news-item .tags > .tag:active {
		opacity: 7;
	}

	.news-list .news-item .tags > .tag:nth-child(1) {
		margin-left: 0;
	}

	.news-list .news-item .title-wrap {
		position: relative;
		margin-top: 32px;
	}

	.news-list .news-item .title-wrap .type {
		position: absolute;
		color: #FF8000;
		font-size: 34px;
		font-weight: bold;
	}

	.news-list .news-item .title-wrap .title {
		color: #231F20;
		font-size: 34px;
		font-weight: bold;
	}

	.news-list .news-item .title-wrap .title::first-letter {
		margin-left: 110px;
		line-height: 36px;
	}

</style>
